import React from "react"
// import { Link } from "gatsby"
import { Box } from "./commonElements"
import { Facebook, Instagram, Twitter } from "@styled-icons/fa-brands"
import styled from "styled-components"

const Anchor = styled.a`
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: white;
  font-weight: 600;
  &:hover {
    color: ${props => props.theme.colors.accent};
  }
  padding: 0 0.5rem;
  -webkit-tap-highlight-color: transparent;
`

const InnerBox = styled(Box)`
  /* max-width: ${props => props.theme.containerSize}; */
  @media ${props => props.theme.device.mobile} {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    gap: 2rem 2rem;
  }
`

// const ExternalAnchor = styled(AnchorG)`
//   text-decoration: underline;
//   color: ${props => props.theme.colors.text};
//   font-weight: 600;
//   &:hover {
//     color: ${props => props.theme.colors.accent};
//   }
//   padding: 0 1rem;
// `

const Footer = () => {
  return (
    <Box
      alignItems="center"
      width="100%"
      borderRadius="0px"
      // backgroundColor="white"
      // padding="3rem 1rem"
      // gap="1rem"
      // alignSelf="flex-end"
    >
      <InnerBox
        flexDirection="row"
        justifyContent="space-around"
        // maxWidth="60rem"
        width="100%"
        backgroundColor="#3a3a3a"
        padding="4rem 1rem"
      >
        <Anchor href="/about/" alt="About us page">
          About Us
        </Anchor>
        {/* <ExternalAnchor href="https://forms.gle/YtK6E6Q6whzk35Bb8" target="_blank" alt="Feedback">Feedback Us</ExternalAnchor> */}
        <Anchor href="/contact/" alt="Contact us page">
          Contact Us
        </Anchor>
        <Anchor href="/signup/" alt="Sign up page">
          Sign up
        </Anchor>
        <Anchor href="/login/" alt="Log in page">
          Log in
        </Anchor>
        <Anchor href="/privacy-policy/" alt="Privacy policy page">
          Privacy Policy
        </Anchor>
        <Anchor href="/latest-updates/" alt="Latest updates page">
          Latest Updates
        </Anchor>
        <Anchor href="/embed-tutorial/" alt="Embed tutorial page">
          Embed Tools
        </Anchor>
      </InnerBox>
      <InnerBox
        flexDirection="row"
        justifyContent="space-around"
        // maxWidth="60rem"
        width="100%"
        backgroundColor="#3a3a3a"
        padding="0rem 1rem 5rem"
      >
        <Anchor
          href="https://www.facebook.com/FlipSimu"
          target="_blank"
          alt="Follow us on Facebook"
        >
          <Facebook size={16} style={{ marginRight: ".5rem" }} />
          Follow Facebook
        </Anchor>
        {/* <ExternalAnchor href="https://forms.gle/YtK6E6Q6whzk35Bb8" target="_blank" alt="Feedback">Feedback Us</ExternalAnchor> */}
        <Anchor
          href="https://www.instagram.com/flipsimu/"
          target="_blank"
          alt="Follow us on Instagram"
        >
          <Instagram size={16} style={{ marginRight: ".5rem" }} />
          Follow Instagram
        </Anchor>
        <Anchor
          href="https://twitter.com/FlipSimu"
          target="_blank"
          alt="Follow us on Twitter"
        >
          <Twitter size={16} style={{ marginRight: ".5rem" }} />
          Follow Twitter
        </Anchor>
      </InnerBox>
    </Box>
  )
}

export default Footer
